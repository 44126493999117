import React from "react";
import styled from "styled-components";

export const Seal = () => {
	return (
		<Wrap>
			<svg width="192" height="192" viewBox="0 0 192 192">
				<path d="M119.09,12.21l2.67-8.53,3.46,1.08c.41,.13,.79,.3,1.12,.5s.61,.45,.83,.73c.22,.28,.36,.61,.42,.97,.06,.37,.02,.78-.13,1.24-.19,.62-.49,1.06-.88,1.34s-.84,.42-1.34,.44c-.5,.02-1.03-.06-1.58-.24l-2.1-.66-1.11,3.55-1.36-.43Zm2.83-4.27l2.1,.66c.58,.18,1.03,.19,1.36,.03,.33-.16,.56-.45,.69-.86,.13-.41,.11-.78-.07-1.11-.18-.32-.56-.57-1.14-.76l-2.1-.66-.84,2.69Z" />
				<path d="M127.94,15.16l3.59-8.19,3.56,1.56c.51,.22,.93,.51,1.25,.84,.32,.34,.52,.71,.6,1.13,.08,.42,.01,.86-.19,1.33-.16,.36-.37,.64-.64,.85-.27,.21-.56,.35-.87,.42-.31,.07-.61,.09-.9,.06,.27,.17,.47,.38,.61,.64,.14,.25,.2,.53,.2,.85s-.08,.65-.24,1.01l-.94,2.15-1.31-.57,.85-1.94c.17-.38,.25-.7,.24-.97,0-.26-.1-.49-.29-.68-.18-.19-.46-.37-.83-.53l-1.86-.82-1.51,3.45-1.31-.57Zm3.31-3.99l1.96,.86c.33,.15,.64,.22,.91,.23,.27,0,.51-.06,.71-.2,.2-.14,.36-.34,.48-.61,.12-.28,.17-.54,.13-.78s-.15-.45-.34-.65c-.19-.19-.45-.37-.79-.51l-1.96-.86-1.11,2.53Z" />
				<path d="M139.83,21.28c-.53-.31-.96-.69-1.3-1.15-.34-.45-.58-.95-.71-1.5-.13-.55-.15-1.12-.04-1.73,.11-.61,.34-1.23,.71-1.85,.36-.62,.78-1.12,1.26-1.5,.48-.38,.99-.66,1.53-.82,.54-.16,1.1-.2,1.66-.12s1.11,.28,1.64,.59c.54,.32,.97,.7,1.31,1.16,.34,.46,.57,.96,.7,1.52,.13,.55,.14,1.13,.04,1.74-.1,.61-.33,1.21-.69,1.82-.37,.62-.79,1.13-1.27,1.52-.48,.39-.99,.66-1.54,.81-.55,.15-1.1,.18-1.66,.11-.56-.08-1.1-.27-1.64-.59Zm.61-1.04c.37,.22,.74,.34,1.12,.39,.38,.04,.75,0,1.12-.13,.37-.13,.72-.35,1.06-.65,.34-.31,.64-.69,.92-1.16,.36-.62,.57-1.21,.63-1.78,.06-.57-.03-1.09-.25-1.55s-.58-.84-1.07-1.12c-.36-.21-.73-.34-1.11-.37-.38-.04-.76,0-1.13,.14-.37,.13-.72,.35-1.06,.65-.34,.3-.64,.68-.91,1.15-.37,.62-.58,1.22-.64,1.79-.06,.57,.03,1.08,.25,1.54,.22,.46,.58,.83,1.06,1.11Z" />
				<path d="M145.91,24.85l5.44-7.1,2.33,1.79c.78,.6,1.36,1.25,1.73,1.94,.37,.69,.5,1.41,.41,2.17s-.45,1.54-1.07,2.34c-.41,.54-.87,.97-1.36,1.29s-1.01,.51-1.55,.59c-.55,.07-1.11,.02-1.69-.16s-1.18-.51-1.78-.97l-2.45-1.88Zm1.87-.08l1.22,.93c.53,.41,1.07,.67,1.59,.79,.53,.12,1.04,.06,1.55-.16,.5-.22,.98-.63,1.44-1.24,.31-.41,.54-.82,.69-1.22,.14-.4,.19-.8,.14-1.19-.05-.39-.19-.77-.43-1.14-.24-.37-.58-.72-1.02-1.06l-1.2-.92-3.98,5.19Z" />
				<path d="M156.38,33.9c-.52-.51-.88-1.05-1.06-1.62-.18-.57-.18-1.14,0-1.73,.17-.58,.54-1.16,1.1-1.74l3.83-3.94,1.03,1-3.68,3.79c-.42,.43-.71,.85-.87,1.24-.16,.39-.2,.76-.11,1.12,.09,.36,.29,.69,.61,1.01,.48,.47,1,.67,1.56,.6,.56-.07,1.15-.43,1.78-1.07l3.68-3.79,1.03,1-3.83,3.94c-.83,.86-1.69,1.29-2.57,1.31-.88,.02-1.71-.36-2.5-1.12Z" />
				<path d="M163.41,41.6c-.55-.67-.88-1.38-1-2.12-.12-.74-.01-1.47,.31-2.2,.33-.73,.88-1.4,1.65-2.03,.55-.44,1.11-.76,1.7-.95,.58-.19,1.15-.26,1.72-.2,.56,.05,1.09,.22,1.6,.5,.5,.28,.95,.66,1.34,1.14,.46,.57,.76,1.15,.91,1.76s.14,1.19-.02,1.76-.47,1.09-.94,1.54l-.95-1.17c.28-.32,.47-.65,.57-.99,.1-.35,.11-.7,.04-1.07-.08-.37-.26-.73-.56-1.09-.26-.32-.57-.57-.91-.75s-.71-.27-1.11-.28c-.39-.01-.8,.06-1.21,.22-.41,.16-.83,.4-1.24,.73-.56,.46-.97,.95-1.23,1.48-.26,.53-.36,1.05-.31,1.56,.05,.51,.25,.98,.6,1.41,.44,.54,.94,.85,1.52,.96,.58,.1,1.14,0,1.67-.31l.91,1.12c-.56,.37-1.14,.57-1.73,.61-.6,.04-1.18-.07-1.75-.34-.57-.27-1.1-.7-1.57-1.29Z" />
				<path d="M168.65,48.41l6.5-4.2-1.65-2.55,1.01-.65,4.06,6.29-1.01,.65-1.64-2.54-6.5,4.2-.77-1.19Z" />
				<path d="M174.02,57.65l8.17-3.62,1.19,2.69c.4,.9,.6,1.75,.59,2.53s-.24,1.48-.68,2.1c-.45,.62-1.14,1.13-2.06,1.54-.62,.28-1.23,.44-1.81,.48-.58,.04-1.13-.03-1.64-.23-.51-.2-.98-.52-1.41-.96-.42-.44-.79-1.01-1.09-1.7l-1.25-2.83Zm1.68,.82l.62,1.4c.27,.61,.61,1.1,1.02,1.45,.41,.35,.89,.56,1.43,.6s1.17-.08,1.86-.39c.47-.21,.87-.46,1.19-.74,.32-.28,.55-.61,.69-.97,.15-.36,.2-.76,.17-1.2-.04-.44-.17-.91-.39-1.42l-.61-1.38-5.98,2.65Z" />
				<path d="M178.04,67.3l8.53-2.68,1.8,5.75-1.15,.36-1.38-4.39-2.43,.76,1.31,4.19-1.16,.36-1.31-4.19-2.66,.83,1.43,4.55-1.15,.36-1.85-5.91Z" />
				<path d="M180.99,79.09c-.15-.74-.15-1.41,0-2.01s.43-1.1,.83-1.5,.93-.67,1.56-.82l.29,1.44c-.4,.11-.73,.29-.98,.56-.25,.27-.42,.59-.51,.95s-.09,.77,0,1.19c.08,.4,.2,.74,.37,1.01,.17,.27,.37,.47,.61,.59,.24,.12,.51,.15,.81,.09,.37-.07,.63-.27,.78-.58,.15-.32,.23-.74,.24-1.26v-1.46c.02-.47,.09-.91,.23-1.33,.13-.41,.34-.76,.63-1.05,.29-.29,.66-.48,1.12-.57,.49-.1,.96-.04,1.4,.17,.44,.21,.82,.55,1.14,1,.32,.46,.54,1,.67,1.64,.14,.69,.14,1.3,0,1.84-.13,.54-.38,.99-.74,1.35-.36,.36-.82,.61-1.36,.75l-.29-1.44c.31-.08,.56-.22,.77-.43,.21-.21,.35-.47,.43-.79s.08-.69,0-1.11c-.07-.35-.18-.66-.34-.92-.16-.26-.34-.45-.56-.57-.22-.12-.46-.16-.72-.1-.35,.07-.6,.26-.74,.56-.14,.3-.21,.7-.22,1.2l-.02,1.58c0,.49-.07,.93-.2,1.34-.14,.4-.35,.75-.64,1.02s-.66,.47-1.13,.56c-.51,.1-1,.06-1.46-.13-.47-.19-.87-.52-1.22-.99-.35-.47-.59-1.07-.74-1.81Z" />
				<path d="M182.22,85.38l8.88-1.01,.16,1.42-8.88,1.01-.16-1.42Zm0,0l1.19-.14,.16,1.42-1.19,.14-.16-1.42Zm7.69-.88l1.19-.14,.16,1.42-1.19,.14-.16-1.42Z" />
				<path d="M182.62,93.87c-.02-.85,.16-1.6,.54-2.24s.92-1.15,1.61-1.52c.69-.37,1.49-.56,2.4-.58,.72-.02,1.38,.07,1.97,.28,.59,.21,1.09,.51,1.51,.89s.74,.85,.97,1.39c.23,.54,.35,1.12,.37,1.75,.02,.7-.09,1.32-.33,1.87-.24,.55-.57,.99-.99,1.33-.43,.34-.92,.55-1.48,.62l-.04-1.48c.32-.06,.61-.19,.86-.4,.25-.21,.45-.47,.59-.79,.14-.32,.21-.69,.2-1.1-.01-.42-.1-.82-.26-1.18s-.4-.67-.71-.92c-.31-.25-.68-.45-1.12-.58-.44-.14-.94-.2-1.5-.19-.68,.02-1.28,.16-1.8,.42-.52,.26-.91,.61-1.19,1.05-.28,.44-.41,.95-.4,1.53,.01,.49,.12,.92,.31,1.3s.48,.67,.85,.87c.37,.2,.81,.3,1.32,.29l.74-.02-.61,.64-.08-3.23,1.21-.03,.1,3.91-4.72,.12-.03-1.25h.33c.14-.01,.29-.01,.43,0,.15,0,.29,.01,.44,.03-.41-.22-.75-.57-1.04-1.04-.28-.47-.44-1.04-.45-1.71Z" />
				<path d="M182.83,100.74l8.9,.86-.21,2.14-7.55,3.48c.2,0,.4,.02,.61,.04s.42,.03,.63,.05l5.91,.57-.14,1.42-8.9-.86,.19-1.91,7.94-3.67c-.22-.01-.42-.03-.61-.04s-.37-.03-.55-.05l-6.36-.62,.14-1.42Z" />
				<path d="M181.52,111.69l8.74,1.89-1.27,5.89-1.17-.25,.97-4.49-2.48-.54-.93,4.29-1.19-.26,.93-4.29-2.72-.59-1,4.66-1.17-.25,1.31-6.06Z" />
				<path d="M179.46,120.48l8.48,2.82-1.22,3.69c-.18,.53-.42,.98-.72,1.33s-.66,.59-1.07,.7c-.41,.12-.85,.09-1.34-.07-.37-.12-.67-.31-.9-.56-.23-.25-.4-.53-.5-.83-.1-.3-.15-.6-.14-.89-.15,.28-.34,.5-.58,.66-.24,.16-.51,.25-.83,.28-.31,.03-.66-.02-1.03-.15l-2.23-.74,.45-1.36,2.01,.67c.4,.13,.72,.18,.99,.15,.26-.03,.48-.15,.65-.35s.32-.49,.45-.87l.64-1.93-3.57-1.19,.45-1.36Zm4.28,2.93l-.68,2.04c-.12,.35-.16,.66-.14,.93,.02,.27,.11,.5,.26,.69,.15,.19,.37,.32,.65,.42,.29,.1,.55,.12,.79,.06,.23-.06,.44-.19,.61-.39,.18-.21,.32-.48,.44-.83l.68-2.04-2.62-.87Z" />
				<path d="M175.77,136.08c.17-.33,.41-.6,.71-.79s.64-.3,.99-.33,.7,.05,1.04,.21c.34,.17,.61,.41,.81,.71,.2,.3,.31,.64,.33,1s-.04,.71-.21,1.05c-.17,.33-.41,.6-.71,.79-.31,.19-.64,.3-1,.33-.36,.03-.71-.04-1.06-.22-.33-.17-.6-.41-.79-.71-.19-.31-.3-.64-.33-1s.04-.7,.21-1.04Z" />
				<path d="M169.01,143.31l7.32,5.13-2.23,3.18c-.32,.46-.68,.81-1.07,1.06-.39,.25-.8,.37-1.22,.37-.42,0-.84-.15-1.26-.45-.32-.22-.56-.49-.7-.8-.15-.31-.23-.62-.24-.94,0-.32,.03-.62,.12-.9-.22,.23-.47,.39-.74,.47-.27,.08-.56,.09-.87,.03s-.62-.21-.94-.43l-1.92-1.35,.82-1.17,1.74,1.22c.34,.24,.64,.38,.9,.43,.26,.05,.5,0,.73-.15,.23-.14,.45-.38,.68-.71l1.17-1.66-3.08-2.16,.82-1.17Zm3.26,4.03l-1.23,1.76c-.21,.3-.34,.58-.4,.85-.06,.27-.04,.51,.06,.73s.26,.42,.51,.59c.25,.18,.5,.27,.74,.28,.24,.01,.47-.06,.7-.2,.23-.15,.45-.37,.66-.67l1.23-1.76-2.26-1.58Z" />
				<path d="M163.31,151.1l6.73,5.88-3.97,4.54-.9-.79,3.03-3.46-1.91-1.67-2.89,3.31-.91-.8,2.89-3.31-2.1-1.83-3.13,3.59-.9-.79,4.08-4.66Z" />
				<path d="M154.42,160.11c.64-.59,1.33-.96,2.05-1.12,.73-.16,1.47-.1,2.21,.18,.74,.28,1.45,.79,2.13,1.52,.48,.52,.83,1.07,1.05,1.64,.22,.57,.32,1.14,.31,1.7-.02,.56-.15,1.11-.4,1.62-.25,.52-.6,.99-1.06,1.41-.54,.49-1.1,.83-1.7,1.01-.59,.18-1.18,.21-1.76,.08-.58-.12-1.11-.41-1.6-.84l1.11-1.02c.33,.26,.67,.43,1.03,.51,.35,.08,.71,.07,1.07-.03s.71-.31,1.06-.62c.31-.28,.54-.6,.69-.96s.22-.73,.21-1.12c-.01-.39-.11-.79-.29-1.2-.18-.41-.45-.8-.81-1.19-.49-.53-1-.91-1.55-1.14s-1.07-.3-1.58-.22c-.51,.08-.97,.31-1.38,.68-.51,.47-.8,.99-.86,1.57s.07,1.13,.41,1.65l-1.06,.98c-.4-.54-.64-1.1-.71-1.69-.08-.59,0-1.18,.24-1.77,.24-.59,.63-1.14,1.19-1.65Z" />
				<path d="M149.53,164.59l5.28,7.22-4.86,3.56-.71-.97,3.71-2.72-1.5-2.05-3.55,2.59-.72-.98,3.55-2.59-1.64-2.25-3.84,2.81-.71-.97,5-3.66Z" />
				<path d="M142.18,169.81l4.42,7.77-1.87,1.06-7.19-4.17c.1,.17,.21,.34,.32,.52s.21,.36,.32,.55l2.93,5.17-1.24,.71-4.41-7.78,1.67-.95,7.57,4.38c-.12-.19-.22-.36-.32-.52-.09-.16-.19-.32-.28-.48l-3.15-5.55,1.24-.71Z" />
				<path d="M130.59,175.84l3.03,7.12,2.8-1.19,.47,1.1-6.89,2.93-.47-1.1,2.79-1.18-3.03-7.12,1.3-.55Z" />
				<path d="M119.5,179.87l4.35,8.17-1.51,.35-2.9-5.66c-.07-.13-.14-.26-.21-.39-.07-.13-.13-.26-.2-.39-.07-.13-.13-.27-.2-.42,.01,.24,.01,.47,0,.69s-.01,.44,0,.66l-.18,6.38-1.88,.44-2.9-5.66c-.07-.13-.14-.26-.21-.4-.07-.13-.13-.27-.2-.4-.07-.13-.14-.27-.2-.43,0,.25,0,.49,0,.7,0,.21,0,.44,0,.66l-.16,6.37-1.41,.33,.3-9.25,1.82-.42,3.07,5.99c.08,.16,.17,.32,.26,.5,.09,.18,.19,.38,.29,.61,0-.25,0-.48,0-.68,0-.2,0-.39,0-.56l.21-6.75,1.84-.43Z" />
				<path d="M103.68,182.45c.61-.05,1.19,0,1.73,.17,.54,.17,1.02,.44,1.45,.81,.42,.37,.77,.83,1.04,1.39,.27,.56,.43,1.2,.5,1.92,.06,.71,.01,1.37-.15,1.96-.17,.59-.42,1.11-.77,1.56s-.78,.8-1.28,1.06c-.51,.26-1.07,.42-1.68,.47-.62,.06-1.2,0-1.74-.18-.54-.17-1.03-.45-1.45-.83-.43-.38-.77-.84-1.04-1.4s-.43-1.18-.49-1.89c-.06-.72-.01-1.38,.15-1.98,.17-.6,.43-1.11,.79-1.55,.36-.44,.79-.79,1.29-1.05,.5-.26,1.06-.42,1.68-.47Zm.11,1.2c-.42,.04-.8,.15-1.14,.34-.33,.19-.61,.44-.84,.76-.22,.32-.38,.7-.48,1.15s-.12,.94-.07,1.48c.06,.71,.24,1.32,.52,1.81s.65,.87,1.1,1.11c.45,.25,.96,.35,1.52,.3,.41-.04,.79-.15,1.12-.34,.33-.19,.61-.45,.84-.77,.22-.32,.38-.7,.48-1.15,.1-.44,.12-.93,.08-1.46-.06-.72-.24-1.33-.52-1.83s-.65-.87-1.1-1.11c-.45-.24-.95-.34-1.51-.29Z" />
				<path d="M96.64,183.06l-.25,8.94-3.88-.11c-.56-.02-1.05-.12-1.48-.31s-.75-.46-.98-.82c-.23-.36-.34-.79-.32-1.3,.01-.39,.1-.73,.28-1.03,.17-.29,.39-.53,.65-.72s.53-.32,.82-.39c-.31-.06-.58-.18-.8-.36-.22-.18-.39-.42-.5-.71s-.17-.64-.16-1.03l.07-2.35,1.43,.04-.06,2.12c-.01,.42,.03,.75,.14,.99,.1,.24,.28,.42,.52,.53,.24,.11,.57,.17,.97,.18l2.03,.06,.11-3.77,1.43,.04Zm-1.57,4.94l-2.14-.06c-.37,0-.68,.03-.93,.13-.25,.1-.45,.25-.58,.45-.13,.2-.2,.45-.21,.75,0,.31,.05,.56,.17,.77,.12,.21,.31,.37,.56,.48,.25,.11,.56,.17,.92,.18l2.14,.06,.08-2.76Z" />
				<path d="M87,182.61l-1.22,8.86-1.39-.19,.52-3.81c-.09,.09-.19,.18-.3,.26-.11,.08-.22,.17-.34,.27l-3.5,2.78-1.77-.24,4.81-3.76,1.35-1.07,.45-3.29,1.39,.19Zm-5.45-.75l2.94,4.66-1.1,.84-3.73-5.76,1.88,.26Z" />
				<path d="M71.52,181.96c.36,.1,.66,.29,.91,.55,.25,.27,.42,.57,.51,.92,.09,.35,.09,.7-.02,1.06-.1,.37-.29,.68-.55,.93s-.57,.42-.92,.52c-.35,.1-.7,.09-1.07-.01-.36-.1-.66-.29-.91-.55-.24-.26-.41-.57-.51-.92-.1-.35-.09-.71,.02-1.08,.1-.36,.29-.66,.55-.91,.26-.24,.57-.41,.92-.51s.7-.09,1.06,0Z" />
				<path d="M59.81,174.92c.79,.36,1.4,.85,1.83,1.45,.43,.61,.66,1.31,.7,2.11,.03,.79-.16,1.65-.57,2.55-.29,.64-.66,1.18-1.1,1.6-.44,.43-.92,.74-1.45,.95s-1.08,.29-1.65,.26c-.57-.03-1.14-.17-1.71-.43-.67-.3-1.2-.69-1.59-1.17-.4-.48-.65-1.01-.76-1.59s-.06-1.18,.15-1.8l1.37,.63c-.11,.41-.14,.79-.07,1.15,.06,.36,.21,.68,.44,.97s.56,.54,.99,.73c.38,.17,.76,.26,1.15,.27,.39,0,.76-.08,1.11-.24,.36-.17,.69-.41,.99-.74,.3-.32,.56-.73,.78-1.21,.3-.66,.45-1.28,.45-1.87,0-.59-.14-1.1-.41-1.54-.27-.44-.66-.77-1.16-1-.63-.29-1.22-.35-1.78-.18-.56,.17-1.02,.51-1.36,1.02l-1.31-.6c.34-.58,.76-1.01,1.28-1.32,.52-.3,1.09-.46,1.72-.47s1.29,.14,1.98,.46Z" />
				<path d="M54.77,172.78l-7.74,5.76-1.42-.86,1.52-9.53,1.36,.83-1.37,7.26c-.03,.16-.06,.31-.08,.45s-.06,.28-.1,.44c.12-.11,.23-.21,.35-.3,.12-.09,.24-.18,.36-.28l5.83-4.56,1.29,.78Zm-3.28,.98l-.62,1.03-3.66-2.22,.62-1.03,3.66,2.22Z" />
				<path d="M43.11,164.82c.6,.46,1.04,.97,1.32,1.52s.4,1.11,.36,1.68c-.04,.57-.25,1.12-.63,1.64l-1.16-.9c.23-.35,.36-.7,.37-1.07,.01-.37-.07-.72-.25-1.05s-.44-.63-.78-.9c-.32-.25-.64-.42-.95-.52s-.59-.11-.85-.04c-.26,.07-.49,.22-.67,.47-.23,.3-.29,.61-.2,.95,.1,.34,.31,.71,.65,1.1l.95,1.1c.3,.36,.54,.74,.71,1.14s.24,.8,.22,1.21c-.03,.4-.18,.79-.47,1.16-.31,.4-.7,.66-1.17,.79-.47,.13-.98,.13-1.52,0-.54-.14-1.07-.4-1.58-.8-.55-.43-.96-.89-1.22-1.38s-.37-1-.33-1.5c.04-.51,.21-1,.53-1.46l1.16,.9c-.18,.26-.28,.54-.3,.83s.05,.58,.2,.88,.39,.57,.73,.83c.28,.22,.57,.37,.86,.47,.29,.09,.56,.11,.8,.06,.25-.05,.45-.18,.61-.39,.22-.28,.28-.59,.19-.9-.09-.32-.3-.67-.62-1.04l-1.02-1.2c-.32-.37-.56-.74-.73-1.14s-.23-.79-.2-1.19c.03-.4,.19-.79,.49-1.17,.32-.41,.71-.7,1.19-.87,.48-.17,1-.18,1.57-.06,.57,.13,1.15,.42,1.74,.87Z" />
				<path d="M38.07,160.98l-6.17,6.47-4.36-4.16,.83-.87,3.33,3.17,1.75-1.84-3.18-3.03,.84-.88,3.18,3.03,1.92-2.02-3.45-3.29,.83-.87,4.48,4.27Z" />
				<path d="M27.1,148.76c.46,.6,.76,1.2,.89,1.81s.1,1.17-.09,1.71-.53,1.01-1.03,1.43l-.89-1.16c.32-.27,.53-.59,.63-.94s.12-.71,.03-1.08-.26-.73-.52-1.07c-.25-.32-.51-.57-.78-.75-.27-.17-.54-.26-.81-.26-.27,0-.53,.09-.77,.28-.3,.23-.44,.52-.44,.87s.12,.76,.34,1.23l.64,1.31c.2,.43,.33,.86,.39,1.29s.03,.84-.1,1.22-.38,.72-.75,1c-.4,.31-.84,.46-1.33,.47-.49,0-.98-.13-1.46-.4-.49-.27-.93-.66-1.32-1.18-.42-.55-.7-1.1-.82-1.65-.12-.54-.1-1.06,.07-1.54s.46-.91,.89-1.28l.89,1.16c-.24,.21-.41,.45-.5,.72-.09,.28-.11,.57-.04,.9,.07,.32,.23,.65,.49,.99,.22,.28,.45,.51,.71,.67,.25,.16,.51,.25,.76,.26,.25,.01,.48-.06,.69-.22,.28-.22,.42-.49,.41-.82s-.12-.72-.33-1.17l-.68-1.43c-.22-.44-.35-.86-.41-1.29-.06-.42-.02-.82,.12-1.2,.13-.38,.39-.71,.77-1,.41-.32,.87-.49,1.37-.53,.5-.04,1.01,.08,1.53,.35,.52,.27,1,.7,1.46,1.29Z" />
				<path d="M22.24,142.15l-6.59,4.05,1.59,2.59-1.02,.63-3.92-6.38,1.02-.63,1.58,2.58,6.59-4.05,.74,1.21Z" />
				<path d="M17.55,133.1c.31,.66,.45,1.29,.42,1.88-.03,.59-.23,1.13-.6,1.62s-.91,.9-1.64,1.24l-4.96,2.35-.61-1.29,4.78-2.26c.55-.26,.96-.54,1.25-.85,.29-.31,.45-.65,.5-1.01,.04-.37-.03-.75-.22-1.16-.29-.61-.7-.98-1.25-1.11-.55-.13-1.23,0-2.04,.38l-4.78,2.26-.61-1.29,4.96-2.35c1.08-.51,2.03-.62,2.86-.32,.83,.29,1.48,.93,1.95,1.92Z" />
				<path d="M14.66,127.09l-8.48,2.84-.93-2.79c-.31-.94-.43-1.79-.35-2.57,.08-.78,.37-1.45,.88-2.02s1.24-1.02,2.2-1.34c.65-.22,1.26-.32,1.84-.31,.58,.01,1.12,.14,1.62,.38,.49,.24,.93,.6,1.31,1.08,.38,.48,.69,1.08,.93,1.8l.98,2.93Zm-1.59-.98l-.49-1.45c-.21-.64-.51-1.15-.88-1.54-.37-.39-.83-.64-1.37-.73-.54-.1-1.17-.03-1.89,.21-.49,.16-.91,.37-1.25,.63-.34,.25-.6,.56-.78,.9s-.27,.74-.28,1.18c0,.44,.08,.93,.26,1.45l.48,1.43,6.2-2.08Z" />
				<path d="M11.58,117l-8.7,2.07-.33-1.39,8.7-2.07,.33,1.39Zm-7.53,1.79l-1.17,.28-.33-1.39,1.17-.28,.33,1.39Zm7.53-1.79l-1.17,.28-.33-1.39,1.17-.28,.33,1.39Z" />
				<path d="M10.51,112.26l-8.84,1.36-.92-5.96,1.19-.18,.7,4.54,2.51-.39-.67-4.34,1.2-.18,.67,4.34,2.75-.42-.73-4.71,1.19-.18,.94,6.12Z" />
				<path d="M9.37,100.17c.04,.76-.07,1.42-.3,1.99s-.59,1.02-1.05,1.35-1.01,.53-1.66,.58l-.07-1.47c.42-.04,.77-.18,1.05-.41s.5-.52,.64-.87c.14-.35,.2-.75,.18-1.18-.02-.41-.09-.76-.22-1.06-.12-.3-.3-.52-.52-.68-.22-.16-.48-.23-.79-.21-.37,.02-.66,.17-.85,.46s-.34,.69-.42,1.21l-.23,1.44c-.08,.46-.22,.89-.41,1.28-.19,.39-.46,.7-.78,.94-.33,.24-.72,.37-1.19,.4-.5,.02-.96-.1-1.36-.38-.4-.27-.73-.66-.97-1.16-.25-.5-.38-1.07-.41-1.72-.03-.7,.06-1.3,.27-1.82,.21-.52,.52-.93,.93-1.23,.41-.3,.9-.48,1.46-.54l.07,1.47c-.31,.03-.59,.13-.82,.31s-.41,.41-.54,.72c-.13,.3-.18,.67-.16,1.1,.02,.36,.08,.67,.2,.96,.12,.28,.27,.5,.47,.65,.2,.15,.43,.22,.69,.21,.36-.02,.63-.16,.81-.44s.31-.66,.39-1.15l.26-1.56c.07-.48,.2-.91,.4-1.29,.19-.38,.46-.69,.78-.92s.73-.36,1.2-.38c.52-.02,.99,.09,1.43,.35,.43,.26,.79,.64,1.06,1.16s.42,1.15,.46,1.9Z" />
				<path d="M7.11,87.61c-.04,.37-.16,.71-.38,1-.22,.29-.49,.51-.81,.67s-.67,.21-1.04,.18c-.38-.04-.72-.16-1.01-.37s-.52-.48-.67-.81c-.16-.33-.22-.68-.18-1.05,.03-.37,.16-.7,.38-.99,.22-.29,.49-.51,.81-.67,.33-.16,.68-.22,1.06-.18,.37,.04,.7,.16,.99,.38,.29,.22,.51,.49,.67,.81,.16,.32,.22,.67,.18,1.04Z" />
				<path d="M11.42,76.8c-.15,.65-.4,1.15-.74,1.5-.34,.35-.76,.57-1.26,.67-.49,.09-1.03,.07-1.61-.07l.32-1.39c.35,.07,.67,.1,.96,.07,.29-.02,.53-.12,.74-.29,.2-.17,.34-.42,.42-.76,.08-.37,.07-.67-.04-.91-.11-.24-.29-.44-.54-.6-.25-.15-.56-.27-.93-.36l-5.91-1.37,.32-1.39,6.1,1.41c.6,.14,1.08,.38,1.44,.71,.36,.34,.61,.74,.73,1.22s.12,1,0,1.56Z" />
				<path d="M14.04,67.65c-.24,.69-.58,1.24-1.01,1.64-.44,.4-.96,.65-1.56,.74-.6,.09-1.28,0-2.04-.26l-5.19-1.8,.47-1.35,4.99,1.73c.57,.2,1.07,.29,1.49,.27,.42-.02,.78-.15,1.06-.37,.29-.23,.5-.56,.65-.98,.22-.64,.18-1.19-.12-1.67-.3-.48-.87-.86-1.73-1.16l-4.99-1.73,.47-1.35,5.19,1.8c1.13,.39,1.88,.98,2.27,1.77s.4,1.7,.05,2.73Z" />
				<path d="M17.76,58.61c-.33,.68-.73,1.22-1.21,1.61-.48,.39-1,.62-1.57,.7-.56,.07-1.14-.02-1.74-.27l.63-1.32c.39,.16,.76,.2,1.12,.14s.69-.22,.98-.46,.53-.56,.72-.95c.18-.37,.28-.71,.31-1.03,.03-.32-.01-.6-.13-.84-.12-.24-.32-.43-.6-.56-.34-.16-.66-.16-.97,0-.31,.16-.62,.45-.94,.86l-.88,1.16c-.29,.37-.61,.68-.97,.93-.36,.25-.73,.4-1.13,.46-.4,.06-.81-.01-1.24-.22-.45-.22-.79-.54-1.02-.98-.23-.43-.33-.93-.31-1.49,.02-.56,.17-1.13,.45-1.71,.3-.63,.67-1.12,1.1-1.48,.43-.36,.9-.57,1.4-.64,.51-.07,1.02,0,1.54,.21l-.63,1.32c-.29-.12-.58-.16-.87-.12-.29,.04-.56,.17-.82,.37-.26,.21-.48,.51-.66,.89-.15,.32-.25,.63-.28,.94s0,.57,.11,.8c.1,.23,.27,.4,.51,.51,.32,.15,.63,.15,.92,0,.29-.16,.59-.43,.89-.83l.97-1.25c.29-.39,.61-.71,.96-.95,.35-.24,.73-.39,1.12-.44,.4-.05,.81,.03,1.24,.23,.47,.22,.83,.55,1.09,.98,.26,.43,.39,.94,.38,1.52s-.17,1.21-.49,1.89Z" />
				<path d="M21.47,51.28l-6.6-4.05-1.59,2.59-1.02-.63,3.91-6.38,1.02,.63-1.58,2.58,6.6,4.05-.74,1.21Z" />
				<path d="M25.28,45.49l-7.22-5.27,.84-1.16,7.22,5.27-.84,1.16Zm-6.25-4.56l-.97-.71,.84-1.16,.97,.71-.84,1.16Zm6.25,4.56l-.97-.71,.84-1.16,.97,.71-.84,1.16Z" />
				<path d="M28.12,41.61l-6.71-5.9,1.42-1.61,8.16,1.59c-.15-.12-.31-.25-.47-.39-.16-.13-.32-.27-.48-.41l-4.46-3.92,.94-1.07,6.71,5.91-1.27,1.44-8.58-1.67c.17,.14,.33,.27,.47,.39s.28,.24,.42,.36l4.8,4.22-.94,1.07Z" />
				<path d="M38.7,30.61l-5.64-6.94,3.01-2.45c.44-.35,.88-.6,1.32-.73,.45-.13,.87-.14,1.28-.02s.77,.38,1.09,.77c.25,.3,.4,.62,.46,.96,.06,.34,.05,.66-.03,.97-.08,.31-.2,.59-.36,.83,.28-.16,.56-.24,.84-.25,.28,0,.57,.06,.85,.21,.28,.15,.54,.37,.79,.68l1.48,1.82-1.11,.9-1.34-1.64c-.26-.32-.51-.54-.75-.66-.24-.11-.48-.13-.74-.06-.26,.08-.54,.24-.85,.49l-1.57,1.28,2.38,2.92-1.11,.9Zm-2.03-4.77l1.66-1.35c.28-.23,.49-.47,.62-.71,.13-.24,.18-.48,.15-.72-.03-.24-.14-.47-.33-.71-.19-.24-.4-.4-.63-.47-.23-.08-.47-.08-.73,0-.26,.08-.53,.23-.81,.47l-1.66,1.35,1.74,2.14Z" />
				<path d="M46.27,24.66l-4.8-7.54,1.21-.77,1.99,3.12,3.74-2.38-1.99-3.12,1.2-.76,4.8,7.54-1.2,.76-2.17-3.4-3.74,2.38,2.17,3.4-1.21,.77Z" />
				<path d="M58.75,17.74c-.55,.26-1.11,.41-1.68,.44-.57,.03-1.11-.06-1.64-.25s-1.01-.51-1.46-.94c-.44-.43-.82-.97-1.13-1.62-.31-.65-.49-1.27-.54-1.89s0-1.19,.18-1.73c.17-.54,.45-1.02,.83-1.44,.38-.42,.85-.77,1.41-1.03,.56-.27,1.13-.41,1.69-.44,.57-.02,1.12,.07,1.65,.27,.53,.21,1.02,.52,1.46,.95,.44,.42,.82,.96,1.12,1.6,.31,.65,.49,1.29,.55,1.9s-.01,1.19-.2,1.73c-.18,.53-.46,1.01-.84,1.43-.38,.42-.84,.76-1.41,1.03Zm-.52-1.08c.38-.18,.7-.42,.95-.71,.25-.29,.42-.63,.52-1,.1-.38,.12-.79,.05-1.24-.06-.45-.21-.92-.45-1.41-.31-.65-.68-1.15-1.12-1.52-.44-.37-.91-.59-1.42-.66-.51-.07-1.02,.01-1.53,.25-.38,.18-.69,.42-.93,.71-.25,.3-.42,.63-.51,1.01-.1,.38-.11,.8-.05,1.24s.21,.91,.44,1.4c.31,.65,.69,1.16,1.12,1.53,.44,.37,.91,.58,1.41,.66,.51,.07,1.01-.01,1.52-.26Z" />
				<path d="M63.94,15.12l.58-9.63,1.57-.53,6.31,7.31-1.51,.51-4.67-5.73c-.11-.13-.2-.24-.29-.35-.09-.11-.18-.23-.28-.36,0,.16,0,.31,0,.46s-.01,.3-.02,.45l-.27,7.4-1.43,.48Zm1.37-3.14l-.38-1.14,4.05-1.37,.38,1.14-4.05,1.37Z" />
				<path d="M74.53,11.74l-1.83-8.75,2.87-.6c.97-.2,1.83-.22,2.59-.05s1.4,.54,1.91,1.11c.51,.57,.87,1.35,1.08,2.34,.14,.67,.17,1.29,.09,1.87-.08,.58-.27,1.1-.57,1.56-.3,.46-.71,.86-1.23,1.18-.52,.32-1.15,.56-1.89,.72l-3.02,.63Zm1.15-1.47l1.5-.31c.66-.14,1.2-.37,1.64-.7,.43-.32,.73-.75,.89-1.28,.16-.52,.16-1.16,0-1.9-.11-.51-.27-.95-.48-1.32s-.48-.66-.8-.88c-.32-.22-.71-.36-1.14-.41-.44-.06-.93-.03-1.47,.09l-1.48,.31,1.34,6.4Z" />
				<path d="M84.82,9.81l-.84-8.9,6-.57,.11,1.2-4.58,.43,.24,2.53,4.37-.41,.11,1.21-4.37,.41,.26,2.77,4.74-.45,.11,1.2-6.17,.58Z" />
				<path d="M96.95,9.38c-.76-.01-1.41-.16-1.97-.43-.55-.27-.98-.65-1.28-1.13-.3-.48-.46-1.05-.48-1.69l1.47,.02c.02,.42,.13,.78,.34,1.08s.48,.53,.83,.7c.34,.16,.73,.25,1.16,.26,.41,0,.76-.04,1.07-.15,.3-.11,.54-.26,.71-.47,.17-.21,.26-.47,.26-.77,0-.37-.13-.67-.41-.88s-.67-.38-1.18-.5l-1.43-.32c-.46-.11-.87-.27-1.25-.49-.37-.22-.67-.5-.89-.84-.22-.34-.33-.74-.32-1.21,0-.5,.16-.95,.46-1.33,.3-.39,.71-.69,1.22-.9,.51-.21,1.09-.31,1.74-.3,.7,.01,1.3,.14,1.8,.38,.5,.24,.89,.58,1.17,1.01s.42,.93,.45,1.49l-1.47-.02c-.01-.32-.1-.6-.25-.84-.16-.25-.38-.44-.68-.59-.3-.14-.66-.22-1.08-.23-.36,0-.68,.04-.97,.14-.29,.1-.51,.24-.68,.43-.17,.19-.25,.42-.25,.68,0,.36,.12,.64,.39,.84,.27,.2,.64,.36,1.12,.47l1.54,.36c.47,.1,.9,.26,1.26,.48,.37,.22,.66,.5,.87,.84,.21,.34,.31,.75,.31,1.22,0,.52-.15,.99-.44,1.4-.28,.42-.69,.74-1.22,.98-.53,.24-1.17,.35-1.92,.34Z" />
				<path d="M109.62,7.85c-.37-.06-.7-.2-.97-.43s-.48-.51-.62-.85c-.13-.33-.17-.68-.12-1.05,.06-.38,.2-.71,.43-.99,.23-.28,.51-.49,.85-.62s.69-.18,1.06-.12,.69,.2,.97,.43c.27,.23,.48,.52,.62,.85,.14,.34,.18,.69,.12,1.07-.06,.37-.2,.69-.43,.97s-.51,.48-.85,.62c-.33,.14-.68,.18-1.05,.12Z" />
			</svg>
		</Wrap>
	);
};

const Wrap = styled.div`
	width: 192px;
	height: 192px;
	position: absolute;
	right: -1em;
	top: calc(-8em + 40px);
	animation: rotation 60s infinite linear;
	svg {
		fill: #a6a6a6;
	}
	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
	@media (min-width: 768px) {
		right: 2em;
	}
`;
