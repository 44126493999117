import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

import { Colors } from "./GlobalStyles";

const outTransition = {
    type: "spring",
    damping: 10,
    stiffness: 100
}

const variants1 = {
    open: { opacity: 1, scale: 1 },
    closed: { opacity: 0, scale: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, transition: outTransition },
}

const variants2 = {
    open: { opacity: 1, scale: 1, x: 0, y: -190, transition: { delay: 0.01 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants3 = {
    open: { opacity: 1, scale: 1, x: -130, y: -130, transition: { delay: 0.02 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants4 = {
    open: { opacity: 1, scale: 1, x: 0, y: -130, transition: { delay: 0.03 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants5 = {
    open: { opacity: 1, scale: 1, x: 127, y: -130, transition: { delay: 0.04 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants6 = {
    open: { opacity: 1, scale: 1, x: -137, y: -71, transition: { delay: 0.05 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants7 = {
    open: { opacity: 1, scale: 1, x: 0, y: -71, transition: { delay: 0.04 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants8 = {
    open: { opacity: 1, scale: 1, x: 146, y: -71, transition: { delay: 0.05 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants9 = {
    open: { opacity: 1, scale: 1, x: -224, y: 10, transition: { delay: 0.06 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants10 = {
    open: { opacity: 1, scale: 1, x: -288, y: 70, transition: { delay: 0.07 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants11 = {
    open: { opacity: 1, scale: 1, x: -167, y: 70, transition: { delay: 0.01 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants12 = {
    open: { opacity: 1, scale: 1, x: -224, y: 128, transition: { delay: 0.02 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants13 = {
    open: { opacity: 1, scale: 1, x: -30, y: 70, transition: { delay: 0.03 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants14 = {
    open: { opacity: 1, scale: 1, x: 155, y: 70, transition: { delay: 0.04 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants15 = {
    open: { opacity: 1, scale: 1, x: -40, y: 128, transition: { delay: 0.05 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants16 = {
    open: { opacity: 1, scale: 1, x: 130, y: 128, transition: { delay: 0.06 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}

const variants17 = {
    open: { opacity: 1, scale: 1, x: -20, y: 188, transition: { delay: 0.07 } },
    closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
    initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
}


export const RoleTags = (props) => {
    return (
        <Tags>
            <span>
                <Tag
                    color={Colors.yellow}
                    shade={Colors.yellowShade}
                    className="tag title"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants1}>
                    Product Designer
                </Tag>
            </span>

            <span>
                <Tag color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants2}>
                    Interface Principles
                </Tag>
            </span>

            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants3}>
                    Visual Design
                    </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants4}>
                    Mockups
                    </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants5}>
                    Style Guides
                    </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants6}
                >
                    Screen Flows
                </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants7}
                >
                    Prototypes
                </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.purple}
                    shade={Colors.purpleShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants8}
                >
                    Front-end Code
                </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.blue}
                    shade={Colors.blueShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants9}
                >
                    CSS
                </Tag>
            </span>
            <span>
                <Tag
                    color={Colors.blue}
                    shade={Colors.blueShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants10}
                >HTML</Tag>
            </span>
            <span>
                <Tag
                    color={Colors.blue}
                    shade={Colors.blueShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants11}
                >Javascript</Tag>
            </span>
            <span>
                <Tag
                    color={Colors.blue}
                    shade={Colors.blueShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants12}
                >Swift</Tag>
            </span>

            <span>
                <Tag
                    color={Colors.green}
                    shade={Colors.greenShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants13}
                >
                    Internal Tools
                </Tag>
            </span>

            <span>
                <Tag
                    color={Colors.green}
                    shade={Colors.greenShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants14}
                >
                    Cross Product Strategy
                </Tag>
            </span>

            <span>
                <Tag
                    color={Colors.green}
                    shade={Colors.greenShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants15}
                >
                    Recruitment
                </Tag>
            </span>

            <span>
                <Tag
                    color={Colors.green}
                    shade={Colors.greenShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants16}
                >
                    Career Development
                </Tag>
            </span>

            <span>
                <Tag
                    color={Colors.green}
                    shade={Colors.greenShade}
                    className="tag"
                    animate={props.open ? "open" : "closed"}
                    initial="initial"
                    variants={variants17}
                >
                    Organizational Principles
                </Tag>
            </span>




        </Tags>

    )
};

const Tags = styled(motion.span)`
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    > span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
    }
`;

const Tag = styled(motion.span)`
    display: block;
    background-color: ${props => props.color};
    color: ${props => props.shade};
    padding: 0.5em 0.75em;
    border-radius: 9px;
    white-space: nowrap;
    text-align: center;
    min-width: 100px;
    &.title {
        padding: 0.5em 0.75em;
        font-size: 0.9em;
    }
`;
